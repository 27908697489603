import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';

const CustLoader = React.lazy(() => import("components/CustLoader/CustLoader"))
const App = React.lazy(() => import("./App"))
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );


root.render(
  <React.Suspense fallback={<CustLoader />}>
    <App />
  </React.Suspense>
);

reportWebVitals();
